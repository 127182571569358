.pricing {
    .card_price {
        font-weight: 700;
        font-size: 3.33vw;
        line-height: 4.44vw;
    }

    .shadow {
        box-shadow: 0px 16px 32px -4px rgba(145, 158, 171, 0.16);
    }


    .MuiButton-root a {
        color: #FFF !important;
        text-decoration: none;
        font-size: 1.041vw !important;
    }

    .MuiButton-root.Mui-disabled a {
        color: rgba(145, 158, 171, 0.8) !important;
        text-decoration: none;
        font-size: 1.041vw;
    }

    .MuiButtonBase-root.MuiButton-root.Mui-disabled {
        border: 1px solid rgba(145, 158, 171, 0.24) !important;
        border-radius: 8px;
        background-color: transparent !important;
    }
}

//payment page css 
.payment {
    .inner_box {
        box-shadow: none;
        border: 1px solid rgba(145, 158, 171, 0.32);
        border-radius: 8px;
    }

    .payment_box {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .card_price {
        font-weight: 700;
        font-size: 3.33vw;
        line-height: 4.44vw;
    }

}

@media only screen and (max-width:899px) {
    .pricing {

        .card_price {
            font-size: 48px;
            line-height: 64px;
        }

        .MuiButton-root a {
            font-size: 15px !important;
        }

        .mobile-toggle-section {
            margin: 40px auto;
            width: 80%;
        }

    }

    //payment page css 

    .payment {
        .card_price {
            font-size: 48px;
            line-height: 64px;
        }
    }
}