.resetpassword {
  .welcome_text {
    margin-top: 158px;
  }

  .backbtn:hover {
    background-color: transparent !important;
  }


  .toppadd_reset {
    padding: 12vw 0;
  }
}

// new password 

.newpassword {
  .logopadding {
    padding: 1.66vw !important;
  }

  .parapadding {
    padding: 0 2vw;
  }
}

//change password 
.changepassword {
  .changeheading {
    margin-bottom: 2.0138vw;
  }
}