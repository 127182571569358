.error404{
    .error404_img{
        height: 18.055vw;
        margin: 5.55vw 0;
    }
    .error404_img2{
        height: 18.055vw;
    margin: 5.55vw auto;
    width: 22.75vw;
    object-fit: contain;
    }
}
.success_img_demo{
    height: 18.055vw !important;
    margin: 2vw auto !important;
    width: 22.75vw !important;
    object-fit: contain !important;
}
@media only screen and (max-width:899px){
    .error404{
        .error404_img{
            margin: 80px 0;
            height: 245px;
        }
        .error404_img2{
            margin: 80px 0;
            height: 245px;
            width: 100%;
        }
    }
}
.success_img_demo{
    margin:  0;
    height: 270px !important;
    width: 100% !important;    
}
@media only screen and (min-width:1930px){
    .error404 .error404_img2 {
        width: 15.75vw;
    }
}