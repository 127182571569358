.flex-star {
  display: flex;
  gap: 15px;
  align-items: center;
}
.memberside-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 10px;
}
.csv-pdf-section {
  display: flex !important;
  .MuiButtonBase-root {
    min-width: auto;
  }
  .MuiBox-root {
    // margin-right: 10px;
    display: inline-block;
    img {
      cursor: pointer;
    }
  }
}
.allocated-team-table {
  .allocated-th {
    width: 31%;
  }
}
.new-member-table {
  padding: 16px 32px;
}

.addbutton_design {
  td {
    color: #212b36;
    padding: 8px 16px !important;
  }
}

.edit-section-table {
  th {
    font-weight: 700;
  }
  .checkbox-edit-section {
    width: 17%;
    .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-root.MuiCheckbox-colorPrimary {
      margin-left: 1vw;
    }
  }
}

.memberinner-table {
  th {
    text-align: left;
  }

  .MuiButtonBase-root.MuiIconButton-root {
    background-color: #dfe3e8 !important;
  }
}
.member-new-table {
  td {
    color: #212b36;
  }
  .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active {
    color: #212b36;
  }
  .memberinner-table th {
    text-align: left;
    background-color: #dfe3e8 !important;
    padding-left: 16px;
  }
  .MuiTableCell-head:first-of-type {
    box-shadow: none;
  }
  tr {
    border-bottom: 1px solid #dfe3e8;
  }
  tr:last-child {
    border-bottom: 0px solid #fff;
  }
  tbody {
    display: block !important;
    overflow-y: auto;
    // height: 280px;
    height: 490px;
    background-color: #fff;
    border-radius: 2px;
  }
  thead,
  tbody tr {
    display: table !important;
    width: 100%;
    table-layout: fixed;
  }
  .MuiTableCell-paddingCheckbox {
    box-shadow: none !important;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding-left: 16px !important;
  }
  .MuiTableCell-root:last-of-type {
    box-shadow: none !important;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 6%;
  }
  .lastdot-section {
    width: 6%;
  }
}
.report-table-new {
  .MuiTableCell-root:last-of-type {
    box-shadow: none !important;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    width: auto;
  }
}
.member-new-table,
.edit-team-screen,
.team-new-table-data {
  /* width */
  ::-webkit-scrollbar {
    width: 14px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #c4cdd5;
    border-radius: 20px;
    background: #c4cdd5;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #919eab;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #919eab;
  }
}
.member-card-section-new {
  box-shadow: none !important;
  background-color: #f4f6f8 !important;
  border-radius: 0 !important;
  padding: 37px 25px !important;
}
.membertitlepage {
  .MuiContainer-root {
    padding: 0;
  }
  .manage-section {
    padding-left: 24px;
  }
}

.edit-team-screen {
  .memberinner-table th {
    text-align: left;
    background-color: #dfe3e8 !important;
    color: #212b36 !important;
    padding: 11px 14px;
  }
  td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingCheckbox.MuiTableCell-sizeMedium {
    padding: 8px 0 !important;
  }
  .MuiButtonBase-root.MuiTableSortLabel-root:hover {
    color: #212b36 !important;
  }
  tr {
    border-bottom: 1px solid #dfe3e8;
  }
  tr:last-child {
    border-bottom: 0px solid #fff;
  }
  tbody {
    display: block !important;
    overflow-y: auto;
    height: 438px;
    background-color: #fff;
    border-radius: 2px;
  }
  thead,
  tbody tr {
    display: table !important;
    width: 100%;
    table-layout: fixed;
  }
  thead {
    background-color: #dfe3e8 !important;
  }
}
.second-section {
  .MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingCheckbox {
    padding-left: 14px;
  }
  td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingCheckbox.MuiTableCell-sizeMedium {
    padding: 8px 6px !important;
  }
  svg {
    color: #637381;
  }
  tbody {
    display: block !important;
    overflow-y: auto;
    height: 438px;
    background-color: #fff;
    border-radius: 2px;
  }
  thead,
  tbody tr {
    display: table !important;
    width: 100%;
    table-layout: fixed;
  }
  thead {
    background: #00ab55;
  }
  tr {
    border-bottom: 1px solid #dfe3e8;
  }
  tr:last-child {
    border-bottom: 0px solid #fff;
  }
}

.upload-csv-table {
  .MuiTableCell-root:last-of-type {
    width: 25%;

    // button{
    //     width: 100%;
    // }
  }
  .lastdot-section {
    width: 25%;
  }
  td,
  th {
    width: 25%;
    box-shadow: none !important;
  }
  .file-name {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
}
@media only screen and (max-width: 899px) {
  .edit-section-table .checkbox-edit-section {
    width: 10%;
  }
  .memberside-table {
    display: block;
  }
}
@media only screen and (max-width: 600px) {
  .member-new-table td.noapp_tab_section {
    padding-left: 0vw !important;
    text-align: center;
  }
}

@media (min-width: 1920px) and (max-width: 6000px) {
  .memberside-table {
    padding: 1.24vw 0.5vw;
  }

  .memberinner-table .MuiButtonBase-root.MuiIconButton-root {
    border-radius: 50% !important;
    height: auto !important;
  }
}
.MuiButtonBase-root.MuiTableSortLabel-root.Mui-active {
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTableSortLabel-icon {
    opacity: 0;
  }
}
.MuiButtonBase-root.MuiTableSortLabel-root.Mui-active {
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTableSortLabel-icon:hover {
    opacity: 0.5;
  }
}
