.verifycode{
    .logo-img{
        margin: 1.66vw 1.66vw 0;
      }
}
@media only screen and (max-width:899px){
    .verifycode{
      .paddtop{
        padding: 175px 0;
      }
    }
}
@media only screen and (min-width:1600px) {
  .verifycode{
.MuiOutlinedInput-input {
  height: 3.8vw !important;
}
  }
}