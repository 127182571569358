.SuperAdminSidebar {
    .MuiPaper-root.MuiPaper-elevation.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
        border-right: 0px;
    }
}

.new_bar_chart_design{
    .apexcharts-legend{
        width: auto !important;
        height: fit-content !important;
        overflow: hidden !important;
    }
    .apexcharts-legend-series{
        margin-bottom: 10px !important;
        margin-left: 0px !important;
    }
}
.banking_text_section{
    .MuiTypography-root.MuiCardHeader-title{
        text-align: center;
    }
}
.statistics_card{
    .apexcharts-menu-icon{
        display: none;
    }
    .MuiCardHeader-title{
        margin-bottom: 80px;
    }
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded{
    padding-bottom: 3.5vw;
    }
}

.rdrMonths {
    display: none;
}

.error404_img {
    width: 75% !important;
    margin: auto;
}
.month-test-padd{
margin-left: 4px !important;

}

//report-date picker
.report-date-picker {
    align-items: center;

    .range-date-picker {
        border: 1px solid rgba(145, 158, 171, 0.32);
        border-radius: 8px;
        padding: 8px 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    :focus-visible {
        outline: #00AB55 auto 1px;
    }

    ::-webkit-input-placeholder {
        /* Edge */
        font-style: italic;
        font-weight: 400;
        color: #919EAB;
        text-transform: uppercase;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-style: italic;
        font-weight: 400;
        color: #919EAB;
        text-transform: uppercase;
    }

    ::placeholder {
        font-style: italic;
        font-weight: 400;
        color: #919EAB;
        text-transform: uppercase;
    }
}

.react-datepicker__day.react-datepicker__day--selected {
    background-color: #00AB55 !important;
    border-radius: 50%;
}

.react-datepicker__day:hover {
    border-radius: 50% !important;
}

.react-datepicker__day.react-datepicker__day--in-range {
    background-color: rgba(0, 171, 85, 0.4) !important;
    border-radius: 50%;
}

.react-datepicker__day.react-datepicker__day--selected .react-datepicker__day--range-start.react-datepicker__day--in-range {
    background-color: #00AB55 !important;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
    border-radius: 50% !important;
    background-color: #f0f0f0 !important;
    border: 1px solid #ccc;
}

.MuiPickersDateRangePickerInput-rangeInputsContainer {
    .MuiOutlinedInput-input {
        padding: 12.5px 14px;
        font-weight: 400;
        border-radius: 8px;
    }

    ::-webkit-input-placeholder {
        /* Edge */
        // font-style: italic;
        font-weight: 400;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        // font-style: italic;
        font-weight: 400;
    }

    ::placeholder {
        // font-style: italic;
        font-weight: 400;
    }

}

    .rdrSelected,   .rdrStartEdge, .rdrEndEdge{
    color: #00AB55 !important;
   }
   .rdrInRange{
    color: rgba(0, 171, 85, 0.4) !important;
  }
  
  .rdrDayToday{
    .rdrSelected, .rdrStartEdge {
        // color: #fff !important;
        // border: 1px solid rgba(0, 0, 0, 0.6);
    }
    .rdrDayNumber span{
        // color: #000 !important;
    }
     .rdrEndEdge{
        color: #00AB55 !important;
       }
}
.rdrDayToday .rdrDayNumber span:after{
    background: #00AB55 !important;
}
.rdrMonth, .rdrCalendarWrapper {
    width: 100% !important;
}
.rdrDayHovered{
    .rdrDayStartPreview, .rdrDayEndPreview{
        border: 1px solid #00AB55;
    }  
    .rdrDayInPreview{
        border: 1px solid #00AB55;
    } 
}
  
// .rdrEndEdge {
//     border-bottom-right-radius: 50% !important;
//     border-top-right-radius: 50% !important;
//     right: 6px !important;
// }

// .rdrStartEdge {
//     border-bottom-left-radius: 50% !important;
//     border-top-left-radius: 50% !important;
//     left: 6px !important;
// }
// .rdrEndEdge, .rdrInRange, .rdrSelected, .rdrStartEdge {
//     bottom: 0px !important;
//     top: 0px !important;
// }


.MuiButtonBase-root.MuiPickersDay-day.MuiPickersDateRangeDay-day.MuiPickersDateRangeDay-dayOutsideRangeInterval.MuiPickersDay-daySelected,
.MuiButtonBase-root.MuiPickersDay-day.MuiPickersDateRangeDay-day.MuiPickersDay-daySelected {
    background-color: #00AB55 !important;
}

.MuiPickersDateRangeDay-rangeIntervalDay.MuiPickersDateRangeDay-rangeIntervalDayHighlightStart.MuiPickersDateRangeDay-rangeIntervalDayHighlight,
.MuiPickersDateRangeDay-rangeIntervalDay.MuiPickersDateRangeDay-rangeIntervalDayHighlight {
    background-color: rgba(0, 171, 85, 0.4) !important;
}

// .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl, .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused.Mui-focused.MuiInputBase-formControl
// .MuiOutlinedInput-notchedOutline{
//     border-color: #00AB55 !important;
// }
// .MuiOutlinedInput-notchedOutline:hover{
//     border-color: #00AB55 !important;
// }
// .PrivateNotchedOutline-root-1.MuiOutlinedInput-notchedOutline:hover{
//     border-color: #00AB55 !important;
// }
// .MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin:hover{
//     background-color: #00AB55;
// } 
// .MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin{
//     background-color: #00AB55;
// }
.empty_img {
    .error404_img {
        width: 50% !important;
        margin: auto;
    }
}

.companypage{
   .simplebar-wrapper{
    min-height: 500px;
   } 

    ._loading_overlay_overlay,._loading-overlay-transition-enter-done{
        min-height: 500px; 
    }
    ._loading_overlay_wrapper, ._loading_overlay_wrapper--active {
        min-height: 500px; 
    }
}

.trackcard {
    min-height: 16.8vw;
    padding-top: 2.777vw !important;
    padding-bottom: 3.05vw !important;
}

.cycle_img {
     width: 17px !important;
     //height: 20px !important;
     object-fit: contain !important;
}
.author-name-new{
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    white-space: nowrap;
}
.author-text-new{
    font-weight: 600;
    line-height: 1.5714285714285714 !important;
    font-size: 0.875rem !important;
}

.textright {
    text-align: right;
}

.pencil_img_profile {
    width: 18px !important;
    height: 18px !important;
    cursor: pointer;
}
.pencil_img_customer{
    width: 15px !important;
    height: 15px !important;
    cursor: pointer; 
}
.pencil_img {
    width: 15px;
    height: 12px;
    cursor: pointer;
}

.edit_hour_section {
    display: flex;
    align-items: center;
    // margin-bottom: 24px !important;

    .sethour-new-box {
        // background: #F4F6F8;
        background: #DFE3E8;
        box-shadow: 0px 4px 10px rgba(145, 158, 171, 0.3);
        border-radius: 1rem;
        // padding: 1.125rem 1rem 0.937rem 1.1rem;
        padding: 0.5rem 0.68rem 0.72rem 0.685rem;
        text-align: center;
        margin-right: 1.25rem;
        min-width: 203px;
        button {
            border-radius: 1.25rem;
            padding: 0.125rem 0.937rem 0;
            box-shadow: none;
            text-transform: uppercase;
            font-weight: 500;
            color: #fff;
            background-color: #00AB55;
        }
    }

    .time-new-box {
        background: #FFFFFF;
        box-shadow: 0px 4px 3px #DFE3E8;
        border-radius: 0.8125rem;
        padding: 0.68rem 1.3rem 0.87rem;
        margin-bottom: 0.5625rem;
    }
 
    .diff-hour-text {
        margin-left: 3rem;
        color: #00AB55;
        font-weight: 400;
        a{
            text-decoration: underline;
            font-weight: 900;
        }
    }
}
.custom-card{
    
    display: flex;
    justify-content: space-between;
    border-radius: 0.8125rem;
    background-color:#ffffff ;
    box-shadow: 0 4px 3px #dfe3e8;
    margin-bottom: 0.5625rem;
    padding: 0.68rem 1rem 0.87rem;

    .MuiTypography-root{
        color: #212B36;
    }
   
}
.custom-new-box{
    width: 80%;
background: #F4F6F8;
box-shadow: 0px 4px 10px rgba(145, 158, 171, 0.3);
border-radius: 1rem;
padding: 1.125rem 1rem 0.937rem 1rem;
text-align: center;
button{
    color: #fff;
    background-color: #00AB55;
    }
    button:hover{
        color: #fff;
        background-color: #00AB55;
        }
.MuiButtonBase-root{
    box-shadow: none;
    border-radius: 20px;
    padding: 2px 0px;

}
}
.manage-profile-fieldset{
    .custom-new-box{
        width: 100%;
    }  
}
.cardend-section {
    .apexcharts-legend {
        height: 38px !important;
    }
}

//member page css
.manage-member {

    .MuiButtonBase-root.MuiCheckbox-root.Mui-checked,
    .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-indeterminate {
        color: #919EABCC !important;
    }
}
.member-new-table{
    .MuiButtonBase-root.MuiCheckbox-root.Mui-checked,
    .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-indeterminate {
        color: #637381 !important;
    }
   
}
.memberinner-table.edit-section-table.addbutton_design {
    .MuiButtonBase-root.MuiCheckbox-root.Mui-checked,
    .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-indeterminate svg{  color: #00AB55 !important;
    }
    .MuiTableRow-root.Mui-selected.MuiTableRow-hover  {
background-color: #EBF8F1 !important;
    } 
}
//dashboaard 
.dashboard {
    .card_height {
        .MuiBox-root {
            z-index: 99;
        }
    }

    .travel-mode {
        color: #212B36;
    }

    .third-card {
        .card-subheading {
            color: #637381;
        }
    }

    .third-card,
    .six-card {
        height: 100%;
    }

    .forth-card,
    .fifth-card {
        height: 49%;
    }

    .six-card {
        .apexcharts-series.apexcharts-pie-series {
            path {
                stroke-width: 0px;
            }
        }

    }

    .nine-card,
    .six-card,
    .eight-card {
        .apexcharts-legend-marker {
            left: -5px !important;
            top: 1px !important;
        }
    }

    .eight-card {
        .apexcharts-legend-series {
            margin: 12px 12px 6px !important;
        }

        .apexcharts-legend {
            inset: auto 10px -6px !important;

            border-top: 1px solid #919EAB3D !important;
        }
    }
}

.icon_top_div {
    background-color: #FFFFFF !important;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
}

.analytic_chart_option {
    .apexcharts-legend {
        display: none;
    }

    .MuiBox-root {
        padding-bottom: 3.8vw;
    }
}



.icon-bg {
    background: #C4CDD5;
    border-radius: 8px;
    padding: 9px 12px;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    color: #1E1E1E;
}

.divider_mt {
    margin-top: 10px !important;
}

.authors_card_section {
    .MuiCardHeader-root {
        padding: 20px 24px 20px 14px;
        background: rgba(20, 174, 92, 0.13);
    }

    .MuiStack-root {
        padding-top: 21px;
    }
}

.paper_set {
    padding: 8px 0 !important;
}

.skiptext {
    color: #919EAB;
    font-size: 0.99vw !important;
    line-height: 1.25vw !important;
    padding-left: 8px;
    text-decoration-line: underline;
}

.sendemail_btn {
    background: #BABABA !important;
    padding: 0.48vw 1vw !important;
    box-shadow: none !important;
    margin-right: 1.04vw !important;
    font-weight: 600 !important;

}

.deleteuser_btn {
    color: #ffffff !important;
    border: 1px solid #BABABA !important;
    background-color: #919EAB !important;
    box-shadow: none;
    // padding: 0.4vw 1.4vw !important;
    padding: 6px 16px !important;
    text-transform:initial !important;
    // box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.16) !important;

    .MuiButton-startIcon {
        margin-right: 0 !important;
    }
}

.nudge-btn-disable {
    background: #c4cdd5 !important;
    color: #f9fafb !important;
    padding: 0.48vw 1vw !important;
    box-shadow: none !important;
    margin-right: 1.04vw !important;
    font-weight: 600 !important;
}

.sendemail_btn_disable {
    padding: 0.48vw 1vw !important;
    box-shadow: none !important;
    margin-right: 1.04vw !important;
    font-weight: 600 !important;
}

.nudge-btn {
    // padding: 5px 15px !important;
    box-shadow: none !important;
    // margin-right: 1.04vw !important;
    margin-right: 16px !important;
    font-weight: 600 !important;
}

.cancle_btn-team {
    background: #C4CDD5 !important;
    margin-right: 1.04vw !important;
    font-weight: 700 !important;
    box-shadow: 0px 8px 16px #C4CDD5 !important;
    color: #637381 !important;
    text-transform: initial !important;
    padding: 8px 16px !important;
    border-radius: 8px !important;

    li {
        padding: 0;
    }

}
.skip_btn-team{
    background: #DFE3E8 !important;
    // margin-right: 1.04vw !important;
    font-weight: 700 !important;
    box-shadow: none !important;
    color: #212B36 !important;
    text-transform: initial !important;
}

.cancle_btn-profile {
    background: #C4CDD5 !important;
    padding: 8px 6px !important;
    font-weight: 700 !important;
    box-shadow: 0px 8px 16px #C4CDD5 !important;
    color: #637381 !important;
    text-transform: initial !important;
}
.skip-this-team-btn{
    background: #DFE3E8 !important;
    padding: 11px 27px !important;
    font-weight: 700 !important;
    color: #212B36 !important;
    text-transform: initial !important;
    box-shadow: none !important;
 margin: 0 !important;
    li{
        padding: 0 !important;
    }
}

.cancle_btn_shadow {
    box-shadow: none !important;
}

.previous_btn {
    background: #F9FAFB !important;
    padding: 10px 20px!important;
    margin-right: 24px !important;
    font-weight: 700 !important;
    border: 1px solid #C4CDD5 !important;
    color: #212B36 !important;
    box-shadow: none !important;
}
.save-teamnew-btn{
    padding: 10px 35px!important;
    margin-left: 24px !important;
    box-shadow: none !important;
  
}


.newflowbtn {
    border-radius: 10px !important;
    padding: 1px 12px !important;
    width: auto;
    min-width: auto !important;
    margin-left: 16px !important;
    color: #fff !important;
    background-color: #00AB55 !important;
}

.newmemberbtn {
    box-shadow: 0px 8px 16px rgba(0, 171, 85, 0.24);
    border-radius: 8px;

    .MuiButton-startIcon {
        margin-right: 2px;
        padding: 5px 0;
    }
}

.side-line-code {
    padding-right: 21px;
    border-right: 1px solid #DFE3E8;
}

// .team-name-tb {
//     width: 36%;
// }

.search_bar {
    margin-right: 15px !important;

    .MuiInputBase-input.MuiOutlinedInput-input {
        padding: 8.5px 14px 8.5px 0;
    }
}

.only_dashboard_section {
    fieldset {
        border: 1px solid transparent;
        border-top-color: #757575;
        box-sizing: border-box;
        grid-area: 1 / 1;
        width: inherit;
    }

    fieldset:nth-of-type(2) {
        transform: rotate(90deg);
    }

    legend {
        padding: 0 0.64vw;
        text-align: left;
        font-size: 1.25rem;
        line-height: 1.944vw;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.8);

    }

    .light_color_heading {
        color: #FFFFFF !important;
    }

}

//team details 
.form-first-input-pad {
    padding-right: 28px;

    button {
        padding: 8px 10px;

    }

    .MuiButtonBase-root.MuiButton-containedPrimary.MuiButton-sizeLarge {
        margin-bottom: 25px;
    }

    .edit_hour_section {
        .MuiButtonBase-root.MuiButton-containedPrimary.MuiButton-sizeLarge {
            margin-bottom: 0px;
        }
    }

}

.team-detail-inner {
    height: 100%;

    fieldset {
        border: 1px solid #DFE3E8;
        box-sizing: border-box;
        grid-area: 1 / 1;
        width: inherit;
        border-radius: 10px;
        padding: 0px 15px;
        height: 100%;
    }

    // fieldset:nth-of-type(2) {
    //     transform: rotate(90deg);
    // }

    legend {
        text-align: right;
        font-weight: 700;
        color: inherit;

    }

    .light_color_heading {
        color: #FFFFFF !important;
    }

}
.customer-detail-inner-border {
    height: 100%;

    fieldset {
        border: 1px solid #DFE3E8;
        box-sizing: border-box;
        grid-area: 1 / 1;
        width: inherit;
        border-radius: 10px;
        padding: 0px 15px;
        height: 100%;
    }  
}
.organisation-team{
    height: auto;
    .detail-page-profile{
        width: 195px;
        height: 195px;
        // margin: 0 2% 0 5%;
        box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.24), -20px 20px 40px -4px rgba(145, 158, 171, 0.24);
        margin: 0 auto;

    .MuiAvatar-root {
            width: 100% !important;
            height: 100% !important;
        }
    }
    legend {
        text-align: left;
    }
    fieldset {
        height: auto;
    }
}
.notification_heading {
    min-width: 18vw;
    text-align: center;
}

.profile-inner {
    height: auto;

    fieldset {
        padding: 0px 0 0 2rem;
    }
}

.manage-profile-fieldset {
    fieldset {
        border: 1px solid #DFE3E8;
        box-sizing: border-box;
        grid-area: 1 / 1;
        width: inherit;
        border-radius: 10px;
        padding: 0px 20px;
        height: 100%;
    }

    legend {
        font-weight: 700;
        color: inherit;

    }

    .edit_hour_section {
        .cancle_btn-profile {
            padding: 8px 16px !important;
        }
    }

    .cancle_btn-team,
    .save_btn {
        padding: 0.6rem 2rem !important;
    }
}
.manage-profile-fieldset{
    .MuiInputBase-root {
        fieldset {
            padding: 0px 8px;    
        }   
    }
}
.time-text {
    color: #007B55;
}

.detail-page-profile {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    padding: 9px;
    margin: 0 auto;
    border: 1px solid rgba(145, 158, 171, 0.32);

    .MuiAvatar-root {
        width: 180px !important;
        height: 180px !important;

        svg{
            width: 180px !important;
            height: 180px !important; 
        }
    }
    .css-1wpwbv3, .css-1wpwbv3{
        width: 180px !important;
        height: 180px !important;
        padding: 0;
    }
}
.detail-page-profile-new-add{
    .css-1wpwbv3{
        width: 200px;
        height: 200px;
    }

}
//loader
.css-50etie svg circle {
    -webkit-animation: animation-1v1uyfb 1.5s ease-in-out infinite;
    animation: animation-1v1uyfb 1.5s ease-in-out infinite;
    stroke-dasharray: 71, 200;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke: #00ab55 !important;
}

//teams 
.select_input_field {

    .MuiSvgIcon-root.MuiNativeSelect-icon.MuiNativeSelect-iconOutlined,
    .MuiSvgIcon-root,
    svg {
        display: block;
    }
}

.boder-upper {
    border: 1px solid #DFE3E8;
    border-radius: 8px;

    .MuiTypography-h4 {
        font-weight: 400 !important;
    }
}

.customer-detail-border {
    padding: 1.5rem 3.688rem;
    padding-top: 1.5rem !important;
}

.boder-upper-inner {
    border: 1px solid #DFE3E8;
    border-radius: 8px;
}
.edit-team-screen, .boder-upper-inner {

        border: 1px solid #DFE3E8;
        border-radius: 8px !important;

        
 
}
.boder-upper {
    
    .second-section {
        // tbody {
        //     height: 450px;
        // }
        th {
            background:#00AB55;
            color: #fff;
            padding: 11px 14px;
        }

        .first-section {
            th {
                background: rgba(221 225 229 / 90%);
            }
        }
    }
}

.boder-upper-inner {
    padding: 0 !important;

    .MuiTableCell-root:first-of-type,
    .MuiTableCell-root:last-of-type {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        box-shadow: none;
        padding: 11px 14px 11px 9px;
    }
}
.member-serch-btn {
    button{
        padding: 5px 16px;
    }
}
.text-field-width {
    width: 80% !important;
}
.google-place{
    .css-13cymwt-control{
        border: 1px solid #ccc;
        height: 58px;
        border-radius: 8px;
        padding-left: 0.5vw;
    }
   .css-1fdsijx-ValueContainer, .css-t3ipsp-control{
    height: 58px;
    border-radius: 8px;
   } 
   .css-t3ipsp-control:hover{
    border-color: #ccc;
    box-shadow: none;
   }

}
.licences-field-width {
    width: 46% !important;
}

.location-text {
    border: 1px solid #ccc;
    height: 4vw;
    border-radius: 8px;
    padding-left: 0.5vw;
}

.btn-width-inner {
    width: 25%;
    text-transform: initial !important;

}

.delete-end-side {
    justify-content: center !important;
    padding-top: 10px !important;

    button {
        padding: 8px 33px !important;
    }
}
.paddbott0{
    padding-bottom: 10px!important;
}
.form-step-btnend {
    button {
        width: 12%;
    }
}

.select-hover:hover {
    background-color: rgba(0, 171, 85, 0.12) !important;
}

.hoverselectdiv {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.select-btn-form {
    display: none;
}

.select-hover:hover .select-btn-form {
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: #FFFFFF;
    box-shadow: 0px 8px 16px rgba(0, 171, 85, 0.24);
    border-radius: 10px;
    background: #00AB55;
    text-align: center;
    padding: 6px, 16px, 6px, 16px;
    position: relative;
    z-index: 99;
    display: block;
    cursor: pointer;
    width: 5rem;
}


.add-btn-form {
    cursor: pointer;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    box-shadow: 0px 8px 16px rgb(0 171 85 / 24%);
    border-radius: 8px;
    background: #00AB55;
    padding: 4px 6px;
    position: relative;
    z-index: 99;
}

.secondlast-btn{
    border: 2.5px solid !important;
    text-transform: initial !important;
}
.add-btn-form::after {
    position: absolute;
    width: 0;
    height: 0;
    top: -6px;
    border-top: 18px solid transparent;
    border-bottom: 19px solid transparent;
    border-left: 18px solid #00AB55;
    float: right;
    content: "";
    z-index: 9;
}

.add-btn-form-gray {
    background: #c4cdd5 !important;
    box-shadow: 0 8px 16px #c4cdd5 !important;
    color: #637381 !important;
}

.add-btn-form-gray::after {
    border-left: 18px solid #c4cdd5;
}

@media only screen and (max-width:600px) {
     .skip-this-team-btn li {
        padding : 10px 16px !important;
    }
    .skip-this-team-btn {
        margin-bottom : 10px !important;
    }
    .statistics_card{
        .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded{
        padding-bottom: 2rem;
        }
    }
    .new_bar_chart_design {
        div.apexcharts-legend{
            justify-content: center !important;
        }
    }
   .activity_card_section{
    .apexcharts-legend{
    height: 56px !important;
    }
}
    .custom-new-box{
        width:100%;
    }
    .padding0{
        padding: 0px !important;
    }
    .margrigt{
        margin-right: 0 !important;
        margin-left: 0 !important;
        margin-bottom: 20px !important;
      }
    .custom-card{
        flex-direction: column;
    }
    .custom-time-box{
        margin-bottom: 1rem;
    }
    .custome-card-form{
        padding: 24px 0 !important;
    }
    .last-team-graph {
        .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center, .apexcharts-legend.apx-legend-position-top.apexcharts-align-center{
            padding-top: 30px !important;
        }
    }
    .empty_img {
        .error404_img {
            width: 90% !important;
            margin: auto;
        }
    }

    .report-date-picker .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-adornedEnd {
        width: 100%;
    }

    .report-date-picker {
        .css-1lwbda4-MuiStack-root {
            margin-top: 6px;
        }
    }

    .change-profile-img-edit {
        flex-direction: row !important;

        .pencil_img_profile {
            width: 18px !important;
            height: 17px !important;
            margin-top: 10px;
            margin-right: 10px;
        }
    }

    .notification_setting {
        .notification_heading {
            display: contents;
        }

        .css-13aspod-MuiStack-root,
        .css-lbpxlg {
            display: block;
        }

        .MuiSwitch-root.MuiSwitch-sizeMedium {
            margin-top: 0;
        }

        .MuiBox-root {
            padding-left: 0;
        }
    }
      .edit_hour_section{
        align-items: start;
        .sethour-new-box{
            width: 100%;
            margin-bottom: 25px;
        }
         .diff-hour-text{
          margin-left: 0;
        }
    }
}
@media only screen and (max-width:360px) {
    .last-team-graph {
        .apexcharts-legend.apx-legend-position-bottom.apexcharts-align-center, .apexcharts-legend.apx-legend-position-top.apexcharts-align-center{
            padding-top: 3.2rem !important;
        }
    }
}
@media only screen and (max-width:348px) {
    .margbott{
        margin-bottom: 10px !important;
    }
}
@media only screen and (max-width:899px) {
    .form-second-step-width {
        width: 500px !important;
    }
    .google-place{
        .css-13cymwt-control{
            height: 56px;
        }
       .css-1fdsijx-ValueContainer, .css-t3ipsp-control{
        height: 56px;
       } 
    }
    .only_dashboard_section {
        padding-top: 30px;
    }

    .analytic_chart_option {
        overflow-x: auto !important;

        .MuiBox-root {
            padding: 0;
            padding-bottom: 3vw !important;
            // width: 450px;
        }
    }

    .side-line-code {
        border-right: 0;
        padding-right: 0;
    }

    .edit_hour_section {
        flex-direction: column;
    }

    .current-text-top {
        margin-top: 15px;
        margin-left: 0 !important;
    }

    .member-btn-all {
        button {
            margin-bottom: 15px;
        }

    }

    .location-text {
        padding-left: 10px;
        height: 52px;
        background: transparent;
    }

    .dashboard {

        .eight-card {
            .apexcharts-legend {
                justify-content: center !important;
                width: auto !important;
            }
        }
    }

    .form-step-btnend button {
        width: auto;
    }

    .member-serch-btn {
        align-items: center;
        margin-top: 15px;
      
    }

    .text-field-width,
    .licences-field-width {
        width: 100% !important;
    }

    .btn-width-inner {
        width: 50%;
    }

    .boder-upper-inner {
        height: auto;

        .MuiTableContainer-root {
            width: 500px !important;
        }
    }

    .previous_btn,
    .cancle_btn-team {
        padding: 8px 20px !important;
    }

    .cancle_btn-team {
        li {
            min-height: auto;
        }
    }

    .form-first-input-pad {
        padding-right: 0;
    }
}
// @media only screen and (max-width:600px){
  
// }
@media only screen and (max-width:414px) {
    .secondlast-btn {
        margin-left: 0 !important;
        margin-right: 15px !important;
    }

    .deleteuser_btn {
        margin-right: 10px !important;
    }

    .deleteteambtn {
        margin-top: 15px !important;
    }
    .delete_new_marg{
        margin-top: 0px !important;
    }
}
@media (min-width:900px) and (max-width:1024px) {
    .google-place{
        .css-13cymwt-control{
            height: 6vw;
        }
       .css-1fdsijx-ValueContainer, .css-t3ipsp-control{
        height: 6vw;
       } 
    }
}
@media only screen and (max-width:1280px) {

    .sendemail_btn,
    .sendemail_btn_disable {
        padding: 7px 30px !important;
        margin-right: 15px !important;

    }


    .only_dashboard_section {
        legend {
            padding: 5px;
            font-size: 20px;
            line-height: 28px;
        }

    }

    .trackcard {
        min-height: 242px;
        padding-top: 40px !important;
        padding-bottom: 44px !important;
    }

    .paper_set {
        padding: 20px 0 !important;
    }

    .search_bar {
        margin-bottom: 15px !important;
        margin-right: 0;
    }

    .dashboard-card-heading {
        .MuiTypography-root {
            font-size: 18px;
            height: 22px;
        }

    }
}

@media (min-width:900px) and (max-width:1280px) {

    .search_bar {
        margin-top: 0 !important;

        .MuiInputBase-input.MuiOutlinedInput-input {
            font: menu;
            padding: 14.5px 14px 10.5px 0;
        }
    }
}

@media only screen and (min-width:1600px) {
    .activity_card_section{
        .apexcharts-legend{
        height: 52px !important;
        }
    }
    .paper_set {
        padding: 1.56vw 0 !important;
    }
    .custom-card{
        .MuiTypography-root.MuiTypography-body {
            font-size: 0.79rem;
        }
    }
}

.six-card {
    .dashboard-card-heading {
        justify-content: center;
    }

    .MuiCardHeader-content {
        display: contents !important;
    }
}

@media (min-width:1920px) and (max-width:6000px) {
    .new_bar_chart_design{
        foreignObject {
            width: 800px !important;
        }
    }

    .delete-end-side button {
        padding: 0.2vw 1.8vw !important;
    }

    .notification_heading {
        min-width: 8vw;
    }

    .location-text {
        height: 2.2vw;
    }

    .sendemail_btn,
    .sendemail_btn_disable {
        padding: 0.3vw 1vw !important;
    }
}

.member-rightside{
    padding-left: 0 !important;
    padding-right: 0 !important;
}