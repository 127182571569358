.login {
    .welcome_text {
        margin-top: 14.95vw;
        margin-bottom: 3.056vw;
    }

    .logo-img {
        width: 10rem !important;
        height: auto !important;
        object-fit: contain;
    }

    .signin_text {
        margin-bottom: 0vw;
        margin-top: 7vw;
    }

    .account-text {
        margin-top: 0;
    }

    button#\:r2\: {
        box-shadow: 0px 8px 16px rgba(0, 171, 85, 0.24);
    }

}

@media only screen and (max-width:1280px) {
    .login {
        .MuiAlert-standardInfo {
            align-items: center;
        }

        .boxpadding {
            padding: 24px;
        }

        .logo-img {
            width: 45px !important;
            height: 45px !important;
        }
        .welcome_login{
            padding: 56px 40px 0px 56px;

            .logo-img {
                width: 10rem !important;
                height: auto !important;
            }
        }
        .signin_text {
            margin-top: 0;
            margin-bottom: 8px;
        }

        .mobilepadd {
            padding: 82px 0px;
        }
    }
}
@media only screen and (max-width:800px) {
    .login{
        .welcome_login{
            padding: 24px;
    
            .logo-img {
                width: 45px !important;
                height: 45px !important;
            }
        }
    }
}
@media (min-width:1920px) and (max-width:6000px) {
    .login .welcome_text {
        margin-top: 9.95vw;
    }
}