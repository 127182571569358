.bulkimport {
    .second_heading {
        margin-top: 2.77vw;
        margin-bottom: 2.77vw;
    }

    .heading_16 {
        font-weight: 600 !important;
    }

    .excleimage {
        width: 1.59vw;
        height: 1.45vw;
    }

    .box_inner_section {
        border: 1px dashed rgba(145, 158, 171, 0.32);
        background: #F4F6F8;
        width: 90%;
        padding: 2.77vw;
        border-radius: 8px;
    }

    .box_inner_section1 {
        border: 1px dashed rgba(145, 158, 171, 0.32);
        background: #161C24;
        width: 90%;
        padding: 2.77vw;
        border-radius: 8px;
    }

    .text_black {
        color: #000;
    }
}

@media only screen and (max-width:899px) {
    .bulkimport {
        .excleimage {
            width: 23px;
            height: 21px;
        }

        .second_heading {
            margin-top: 40px;
            margin-bottom: 40px;
        }

        .box_inner_section {
            width: 100%;
            padding: 40px 0;
        }
    }
}