.tableskeleton {
    table {
        padding: 5px 15px;
    }

    .proname {
        .name {
            width: 15vw;
            height: 2.5vw;
        }

        .price {
            width: 8vw;
            height: 3.5vw;
        }
    }

    .vrtext {
        width: 8vw;
        height: 2vw;
    }

    .qty {
        display: flex;
        align-items: center;
    }

    .vrtext {
        width: 8vw;
        margin-right: 1vw;
    }

    .qttext {
        width: 6vw;
        height: 2vw;
        margin-right: 1vw;
    }

    .delbox {
        width: 2vw;
        height: 2.6vw;
        border-radius: 5px;
    }

    .circle {
        width: 2.5vw;
        height: 2.6vw;
        border-radius: 50px;
    }
}

@media (min-width:280px) and (max-width:600px) {
    .tableskeleton {

        .proname,
        .provariant,
        .proqty,
        .probtn {
            width: 12.5% !important;
        }

        table {
            padding: 5px 15px;
        }

        .proname .price {
            width: 23vw;
            height: 6.5vw;
            margin-bottom: 2.5vw;
        }

        .proname .name {
            width: 40vw;
            height: 6.5vw;
        }

        .vrtext {
            width: 20vw;
            height: 6.5vw;
        }

        .qttext {
            width: 20vw;
            height: 6.5vw;
            margin-right: 2vw;
        }

        .circle {
            width: 5vw;
            height: 6.6vw;
            border-radius: 50%;
        }

        .delbox {
            width: 5vw;
            height: 6.6vw;
            border-radius: 6px;
        }
    }
}