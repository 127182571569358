@charset "UTF-8";
// 5. Components
@import //  'components/button',
'components/table.scss';

// 6. Page-specific styles
@import 'pages/login',
'pages/_resetPassword.scss',
'pages/register',
'pages/verifyCode',
'pages/_pricing.scss',
'pages/error',
'pages/bulkImport',
'pages/dashboard',
'pages/_tableSkeleton',
'pages/dashboardSkeleton.scss',
'pages/resources'
;
@import 'dialog/welcomeTripshiftDialog';
// 7. Themes
@import 'themes/default';

.add-team-selected {
  background-color: #ebf8f1 !important;
}

.faq_field-section{
  .MuiAccordionSummary-content.Mui-expanded.MuiAccordionSummary-contentGutters{
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.Mui-expanded {
    margin: 0px ;
  }
}

.btnleft_right {
  text-align: center;
}

.csv-new-btn {
  min-width: 64px !important;
  padding: 6px 16px !important;
}

.download-template-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.thankyou_para_text{
  padding: 0px 45px;
  width: 70%;
  margin: auto auto 5px !important;
}
.info_tool_tip {
  .MuiIconButton-sizeMedium {
    padding: 0;
  }
svg{
  color: #C4CDD5;
  width: 0.7em;
  height: 0.7em;

}
  position: absolute;
  right: 0px;
}

.MuiTooltip-tooltip {
  // background-color: #FFF !important;
  // color: #919EAB !important;
  border-radius: 10px !important;
  padding: 9px 12px  !important;
  // box-shadow: 0px 4px 10px 0px rgba(145, 158, 171, 0.30);
  box-shadow: 0px 12px 24px -4px #0000001F,
   0px 0px 2px 0px #00000033;  
  font-size: 12px  !important;
  font-style: normal;
  font-weight: 500  !important;
  line-height: 18px;

}

.new_play_store{
  object-fit: contain !important;
  width: 140px !important;
  margin: 0 auto 1.5vw ;
  cursor: pointer;
}
.kmbtn{
  padding: 4px 6px !important;
  min-width: 13px !important;
  color: #919EAB !important;
  text-transform: inherit !important;
} 
.activekmbtn{
  background-color: rgba(0, 171, 85, 0.08) !important;
  font-weight: 900 !important;
}
.disablekmbtn{
  background-color: transparent !important;
}

.disablekmbtn:hover{
  background-color: transparent !important;
}
.green_checkbox {
  svg {
    color: #00AB55 !important
  }
}

.main-sidebar {
  .MuiButtonBase-root.MuiListItemButton-root {
    display: flex;
    padding-left: 16px;
    padding-right: 12px;
    margin-bottom: 4px;
    color: #637381;
    border-radius: 8px;
  }
}

.required-email {
  .MuiFormControlLabel-labelPlacementStart {
    margin-left: 0px;
    margin-right: 16px;
  }

}

.required-notification {
  margin-left: 0 !important;
}

.report-inner-table {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 16px 10px;
}

.datepicker-second {
  margin-left: 8vw !important;
}

.required-email-error {
  text-align: left !important;
}

.past-dayes-text {
  display: flex;
  justify-content: end;
  margin-right: 0.7vw !important;
}

.six-card {
  .apexcharts-legend {
    margin-top: 1vw;
  }
}

// .manage-member{
//   .boder-upper{
.save-chnage-new {
  .MuiButtonBase-root.MuiButton-root {
    padding: 8px 22px;
    border-radius: 8px;
    background-color: #00AB55;
    color: #fff;
  }

  .MuiLoadingButton-root.Mui-disabled {
    background-color: #DFE3E8;
    color: #DFE3E8;
  }

}

.MuiButtonBase-root.MuiButton-root.confirm-btn {
  padding: 0.48vw 1.5vw !important;
  background-color: #00AB55 !important;
  color: #fff !important;
  border-radius: 8px !important;
}

.confirm-btn.MuiLoadingButton-root.Mui-disabled {
  background-color: #DFE3E8 !important;
  color: #DFE3E8 !important;
}

.licences-btn {
  box-shadow: none !important;
}

.licences-btn.MuiLoadingButton-root.Mui-disabled {
  color: #757575 !important;
}

.cancle_btn-team {
  padding: 8px 22px;
  border-radius: 8px;
}

//   }
// }
.boldttext {
  font-weight: 700 !important;
}

.setup-loader {
  ._loading_overlay_overlay {
    min-height: 100vh !important;
  }
}

.mobilehide {
  display: block;
}

.mobileshow {
  display: none;
}

.cross_section {
  margin-top: 10px;
  margin-right: 10px;
}

.MuiPaper-root.MuiPopover-paper {
  width: fit-content;
  border: 1px solid #BABABA;
  border-radius: 8px;
  padding: 0;

  li {
    padding: 8px 24px;
    justify-content: center !important;
  }

  .css-omahdu {
    width: 0;
  }
}

.text-left-dialogs {
  padding: 0 !important;
}

.view_member {
  padding-bottom: 0 !important;
}

.delete_member {
  padding-top: 0 !important;
  justify-content: center !important;
}

.make_admin {
  color: #212B36 !important;
  border-radius: 0 !important;
}

.login_sectionimg {
  width: 220.02px !important;
  height: 346.7px !important;
  margin: auto;
  object-fit: contain !important;
}

.header_menu_right a {
  color: #fff;
  text-decoration: none;
}

.header_menu_right_dark a {
  color: #212B36;
  text-decoration: none;
}

//password validation code 
.password-section-all {
  // background: #FFE7D9;
  background: #F4F6F8;
  border-radius: 16px;
  padding: 15px 19.13px;

  // color: #7A0C2E;
  h6 .MuiTypography-root {
    text-align: left;
  }

  .infoicon-section {
    margin-bottom: 10px;
    line-height: 1.5714285714285714;
    font-size: 0.875rem;
    margin-left: 0px;

    .MuiCheckbox-root {
      background: rgba(255, 72, 66, 0.16);
      border-radius: 12px;
      color: #FF4842;
      ;
      margin-right: 13px;

    }

  }

  // .password-checkbox{
  //   margin-bottom: 10px;
  // }
  .checkicon-section {
    margin-bottom: 10px;
    line-height: 1.5714285714285714;
    font-size: 0.875rem;
    margin-left: 0px;

    .MuiCheckbox-root {
      background: rgba(84, 214, 44, 0.16);
      border-radius: 12px;
      color: #54D62C;
      margin-right: 13px;

    }

  }
}

.greenclo {
  // color: #108B4A;
  color: #00AB55;
}

.request_new_head {
  color: rgba(99, 115, 129, 1);
}

.yelloclo {
  color: #FFBF71;
}

.error-message {
  color: rgb(255, 24, 24);
  line-height: 1.5;
  font-size: 0.75rem;
  font-family: "Public Sans", sans-serif;
  font-weight: 400;
  text-align: left;
  margin-top: 0 !important;
  padding-left: 38px;
}

.profile_error_message {
  padding-left: 0;
  text-align: center;
}

.license-error {
  margin-top: 10px !important;
  padding-left: 0px;
}

.textstyle {
  text-decoration: underline;
  cursor: pointer;
}

.publicprofile {
  .profile_section {
    padding-top: 8.055vw;
  }

  .logopadding {
    padding: 1vw
  }

  .toppadd_reset {
    padding-top: 3vw;
  }
}

// end main section css 
.editLisence {
  margin-top: 20px;
  height: 2.063rem;
  width: 2.975rem;
  border-radius: 5px;
  background: #F5F5F5;
  border: 1px solid #757575;
  border-radius: 5px;
  padding-left: 0.5rem;

}

.link-tag-text {
  color: #212B36 !important;
}

.link-tag-text-dark {
  color: #FFFFFF !important;
}

.header_dark_heading {
  color: #000;
  font-weight: 400 !important;
}

.black_color {
  color: #212B36 !important;
}

.white_color {
  color: #FFF !important;
}

.header_light_heading {
  color: #FFF;
}

.MuiPaper-elevation {
  ul {
    option {
      cursor: pointer;
      padding-left: 1em;
    }
  }
}

.policy-heading {
  line-height: 1.5 !important;
  font-size: 0.9rem !important;
  text-transform: initial !important;
  font-weight: 600 !important;

  .MuiFormControlLabel-label {
    line-height: 1.5 !important;
    font-size: 0.75rem !important;
  }
}

//social login code 
.social-box {
  border: 1px solid rgba(145, 158, 171, 0.32);
  border-radius: 8px;
  padding: 0.85vw 0vw;
  margin-right: 0.694vw;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;

}

.social_icon {
  width: 1.66vw !important;
  height: 1.66vw !important;
  object-fit: contain !important;
}

.social-section {
  margin-top: 2.778vw;
  margin-bottom: 2vw;
}

//social login code end 

//logo code both inner and outer 
.logo-img {
  width: 2.625rem !important;
  height: 2.625rem !important;
}

.newpassword .logo-img {
  width: 6.188rem !important;
  height: 6.188rem !important;
}

.publicprofile {
  .logo-img {
    // width: 4rem !important;
    // height: 4rem !important;
    width: 8rem !important;
    height: 3rem !important;
    object-fit: contain;

  }
}

.navbar {
  .logo-img {
    width: 8rem !important;
    height: 5rem !important;
    object-fit: contain;
  }
}

//end logo code 
.only_social_section {
  fieldset {
    border: 1px solid transparent;
    border-top-color: rgba(145, 158, 171, 0.24);
    box-sizing: border-box;
    grid-area: 1 / 1;
    width: inherit;
    margin-bottom: 2vw;
  }

  fieldset:nth-of-type(2) {
    transform: rotate(90deg);
  }

  legend {
    margin: auto;
    padding: 0 0.64vw;
    text-align: center;
    font-size: 0.9722vw;
    line-height: 1.527vw;
    font-weight: 600;
    color: #637381;

  }

}

.multi-legend-div {
  width: 13rem;
}

.dashboard {
  ul.MuiBox-root {
    bottom: 20px;
    align-items: end;
    justify-content: end;
    top: 0;
    left: 0;
    right: 24px;
  }

  .more-engagement {
    font-size: 0.9722vw !important;
  }
}

.publicprofile .container-style-profile {
  max-width: 1050px;
  margin: 0 0 0 auto !important;
}

.MuiButtonBase.root-MuiIconButton-root:before {
  background-color: transparent;
}

.MuiIconButton-root:before {
  background-color: transparent !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiAppBar-positionFixed.mui-fixed {
  height: fit-content;
  padding-top: 5px;
  box-shadow: none;
}

.add-customer-page {
  margin-top: -50px;
}

.rightside_section_dashboard {
  .toppadd_reset {
    padding: 60px 0 20px;
    min-height: auto;
  }
}

.profile_heading {
  padding-left: 5px;
}

//social login code end 
@media only screen and (max-width:1280px) {
  .publicprofile .container-style-profile {
    max-width: 95%;
    margin: auto !important;

    .profile_heading {
      word-break: break-word;

    }
  }

  .MuiButtonBase-root.MuiButton-root.confirm-btn {
    padding: 10px 30px !important;
  }

  .publicprofile {
    .logopadding {
      padding: 24px
    }

    .toppadd_reset,
    .profile_section {
      padding-top: 100px;
    }
  }

  // placeholder css 

  ::-webkit-input-placeholder {
    /* Edge */
    font-size: 16px;
    line-height: 24px;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 16px;
    line-height: 24px;
  }

  ::placeholder {
    font-size: 16px;
    line-height: 24px;
  }

  .add-customer-page {
    ::-webkit-input-placeholder {
      /* Edge */
      font-size: 1rem;
      line-height: 1.5rem;
      font-style: italic;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: 1rem;
      line-height: 1.5rem;
      font-style: italic;
    }

    input::placeholder::placeholder {
      font-size: 1rem;
      line-height: 1.5rem;
      font-style: italic;
    }

    ::placeholder {
      font-size: 1rem;
      line-height: 1.5rem;
      font-style: italic !important;
    }
  }

  //placeholder css end 

  //social login code 
  .social_icon {
    width: 24px !important;
    height: 24px !important;
    object-fit: scale-down !important;
  }

  .social-box {
    margin-right: 10px;
    padding: 8px 0;
  }

  .social-section {
    margin-top: 40px;
    margin-bottom: 34px
  }

  .only_social_section {
    fieldset {
      margin-bottom: 34px
    }

    legend {
      font-size: 14px;
      line-height: 22px;
    }
  }

  //social login code end 

  .logo-img {
    width: 40px !important;
    height: 40px !important;
  }

  .publicprofile,
  .newpassword {
    .logo-img {
      width: 64px !important;
      height: 64px !important;
    }
  }

  .navbar {
    .logo-img {
      width: 120px !important;
      height: 84px !important;
    }
  }

}

//Auto fill css 
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #FFF inset;
  transition: background-color 5000s ease-in-out 0s;
}

// auto fill dark mode
.MuiInputBase-input.MuiOutlinedInput-input:-webkit-autofill {
  -webkit-box-shadow: none !important;
}

@media (min-width:1100px) and (min-width:1200px) {
  .multi-legend-div {
    width: 9.5rem;
  }
}

@media only screen and (min-width:1500px) {
  .multi-legend-div {
    // width: 19.5rem;
    width: 15.5rem;
  }
}

@media (min-width:1930px) and (max-width:6000px) {

  .super-top-padd,
  .admin_heading {
    padding-top: 8vw;
  }

  .welcome-forgot {
    padding-top: 3vw;
  }

  .multi-legend-div {
    // width: 19.75rem;
    width: 15.5rem;
  }
}

@media only screen and (max-width:600px) {
  .multi-legend-div {
    width: 1.75rem;
  }
  .thankyou_para_text{
    padding: 0px;
    width: 100%;
  }
.commute_text_top{
  display: flex;
  align-items: center;

  .commute_text_new{
    width: 90%;
    display: block;
  }
}
  .btnleft_right {
    text-align: left;
  }

  .required-email {
    .enagagement_new_sec {
      display: block;
    }

    .MuiFormControlLabel-labelPlacementStart {
      display: block;
    }

  }

  .report-inner-table {
    justify-content: center;
  }

  .datepicker-second {
    margin-left: 0vw !important;
  }

  .six-card {
    .apexcharts-legend {
      margin-top: 4vw;
      height: 62px;
    }
  }

  .past-dayes-text {
    margin-right: 4vw !important;
  }

  .mobilehide {
    display: none;
  }

  .mobileshow {
    display: block;
  }
}

@media (min-width:1000px) and (max-width:1100px) {
  .multi-legend-div {
    width: 13rem;
  }
 
}
@media (min-width:920px) and (max-width:1065px) {
  .commute_text_top{
    .commute_text_new{
      margin-right: 1rem;
    }
  }
  .asked_text_new{
    margin-top: 54px !important;
  }
}

@media only screen and (max-width:330px) {
.working_day_btnsection{
  flex-direction: column !important;
  justify-content:center !important;
  align-items: initial !important;
  .MuiButtonBase-root {
    margin-bottom: 15px !important;
  }

}
}

.customer-text-heading {
  font-weight: 700 !important;
}

.pointertest {
  cursor: pointer;
}

.font600 {
  font-weight: 600 !important;
}