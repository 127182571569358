.register {
    .link_text{
        text-decoration-color: #212B36 !important;
        color: #212B36 !important;
        font-weight: 500;
    }
    .light_link_text{
        color: #fff !important;
        text-decoration-color: #fff !important;
    }
    .account-text{
        font-weight: 600;
    }
    .MuiTypography-root.heading_24{
        margin-bottom: 0;
    }
    .logo-img {
        width: 6.188rem !important;
        height: 6.188rem !important;
    }
    .boxpadding{
        padding:1.875vw 2.98vw  ;
    }
    .register-btn {
        color: #fff;
        text-decoration: none;
    }

    .main_heading {
        margin-top: 11.31vw;
    }

    .admin-heading {
        margin-top: 16.25vw;
        padding-right: 5vw;
    }

    .admin-rightsidepad {
        margin-top: 9.0277vw;
    }
    .bottmarg{
        margin-bottom: 0;
    }
}

@media only screen and (max-width:1280px) {
    .register {
        .MuiTypography-root.heading_24{
            margin-bottom: 8px;
        }
        .boxpadding{
            padding:24px  ;
        } 
        .logo-img {
            width: 45px !important;
            height: 45px !important;
        }
        .main_heading{
            padding-right: 0;
        }
    }
}

@media (min-width:1120px ) and (max-width:1280px){
.register {
    .logo-img {
        width: 6.188rem !important;
        height: 6.188rem !important;
    }
    .main_heading{
        padding-right: 2vw;
    }
}
}
@media only screen and (min-width:1600px) {
    .register{
.last_text{
    margin-top: 1.66vw;
}
    }
}
