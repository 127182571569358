.resources {
  padding-left: 22px;
  padding-right: 53px;
  &-title {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 46px;
  }

  &-content {
    background-color: #c8facd;
    padding: 44px 40px;
    border-radius: 16px;
    display: flex;
    gap: 122px;
    align-items: center;
    padding-right: 31px;
    .enable_btn_report {
      text-transform: none;
    }
    .img-banner {
      img {
        min-width: 250px;
      }
    }
  }
  &-board {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;

    &-left-side {
      width: 333px;
      .pdf-card {
        .pdf-container {
          position: relative;
          &:hover {
            opacity: 0.5;
          }

          .pdf-img {
            max-width: 145px;
          }
          .icon {
            display: none;
          }
          &:hover .icon {
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
          }
        }
      }
      .pdf-link {
        display: flex;
        flex-wrap: wrap;
        gap: 43px;
      }
    }

    &-right-side {
      width: clamp(400px, 36vw, 515px);
      margin-right: 40px;
      .resources-faq {
        .MuiPaper-elevation {
          box-shadow: none;
          border-bottom: 1px solid rgb(180, 188, 195);
          border-radius: 0;
          margin-bottom: 0px;
          .MuiAccordionSummary-root {
            padding: 0;
          }
          &::before {
            background-color: transparent;
          }
        }
        .Mui-expanded {
          border-bottom: none;
        }
        .MuiCollapse-root {
          border-top: 1px solid rgb(180, 188, 195);
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .resources {
    padding: 0 24px;
    &-board {
      &-left-side {
        width: 40%;
      }

      &-right-side {
        width: 60%;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .resources {
    &-content {
      flex-direction: column;
      gap: 50px;
    }
  }
}

@media screen and (min-width: 1500px) {
  .resources {
    &-board {
      gap: 53px;
      &-left-side {
        width: fit-content;
      }

      &-right-side {
        width: fit-content;
        margin-right: 40px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .resources {
    &-board {
      flex-direction: column;
      gap: 50px;
      &-left-side {
        width: fit-content;
      }

      &-right-side {
        width: fit-content;
      }
    }
  }
}

@media screen and (min-width: 1920px) {
  .resources {
    width: 1488px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 300px) and (max-width: 400px) {
  .resources {
    &-board {
      &-left-side {
        .pdf-link {
          gap: 30px;
        }
      }
      &-right-side {
        margin-right: 0;
      }
    }
  }
}
