
 .MuiPaper-root.MuiDialog-paper{
  min-width: 450px;
  max-width: 500px;
}
.note-dialogs{
  .MuiPaper-root.MuiDialog-paper{
    min-width: 450px;
    max-width: 540px;
  }
}
.download-template{
  background: #BFCFE3 !important;
  color: #757575 !important;
  box-shadow: 0px 8px 16px rgb(191 207 227 / 90%) !important;
}
.next-select-btn{
  color: #fff !important;
  background-color: #00AB55 !important;
  padding: 8px 16px !important;
  border-radius: 8px !important;
}
.new-cancle-btn{
  padding: 8px 16px !important;
  border-radius: 8px !important;
}

.edit-licences{
  .MuiPaper-root.MuiDialog-paper{
    min-width: 320px;
    max-width: 450px;
  }
  .cancle_btn{
    margin-left: 1.5vw !important;
    box-shadow: none !important;
  }
}

.MuiInputBase-root.MuiOutlinedInput-root{
  option{
    color: #212B36;
  }
}

.toast-dialogs{
  .MuiPaper-root.MuiPaper-rounded {
    border-radius: 8px;
  }
  .MuiPaper-root.MuiDialog-paper{
    min-width: 370px;
    max-width: 11vw;
  }
}
.welcomeTripshift {
  .register-btn {
    color: #fff;
    text-decoration: none;
  }

  .welcomepara {
    padding: 0 0%;
  }
}
.mainly-working{
   font-weight: 700 !important;
   text-transform: initial !important;
   margin-top: 0px;
   span{
    font-weight: 700 !important;
   }
   
span.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-root.MuiCheckbox-colorPrimary {
    padding: 9px;
    color: rgba(145, 158, 171, 0.8) !important;
}
}
//add members 

.addmembers {
  .member-textfield{
    .MuiInputBase-root.MuiOutlinedInput-root{
      min-height: 7.067vw;
      align-items:flex-start;
    }
   
  }
  .member-textfield{
    .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline{
      max-height: 5vw !important;
      overflow-y: scroll !important;
    }
  }
 .main-para {
    font-size: 1.2rem;
    line-height: 1.5rem;
    padding: 0 2%;
    margin-bottom: 2.5rem;
  }

}
.addmemberdialogs{
  .MuiPaper-root.MuiPaper-elevation.MuiAlert-standardError{
    margin-bottom: 0;
  }
}
.manage-member .MuiButtonBase-root.MuiCheckbox-root.Mui-checked,
.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-indeterminate{
  color: #00AB55 !important;
}
//warning Dialog
.warning-dialogsbox{
  .MuiButtonBase-root.MuiCheckbox-root.Mui-checked, .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-indeterminate{
    color: #919EABCC !important;
  }
}
.confirm-btn{
  padding: 0.48vw 1.5vw !important;
  background-color: #00AB55 !important;
  color: #fff !important;
  border-radius: 8px !important;
}
.cancle_btn{
  background: #BFCFE3 !important;
  padding: 0.48vw 1.5vw !important;
  box-shadow: none !important;
  margin-left: 2.98vw !important;
  font-weight: 600 !important;
  color: #757575 !important;
  border-radius: 8px !important;
  box-shadow: 0px 8px 16px rgb(191 207 227 / 90%) !important;
}
.disable_btn_report{
  background: #C4CDD5 !important;
box-shadow: 0px 1px 2px rgba(145, 158, 171, 0.16) !important;
border-radius: 8px !important;
color: #F9FAFB !important;
padding: 0.75rem 1.75rem !important;
}
.delete-alert{
  text-align: center;
}
.MuiDialogActions-root{
  justify-content: center !important;
}
.warning-dialogsbox{
  padding: 2.5rem 1.597vw 0.7vw;

  .MuiDialogActions-root{
    justify-content: center !important;
  }
}
.checkbox-light-heading{
  .MuiTypography-root {
     font-weight: 700 !important;
    color: #000000;
  }
  .MuiSvgIcon-root{
    color:  rgba(145, 158, 171, 0.8);;
  }
  span{
    font-weight: 400;
  }
}
.checkbox-dark-heading{
 color: #FFF;

}
.checkbox-section{
  margin-top: 16px;
  align-items: center;
  display: flex
}
.btn-section-alert{
justify-content: center !important;
}
.delete-alert{
.heading_18_dark{
    color: #FFFFFF;
  }
  .MuiDialogActions-root{
    justify-content: center !important;
  }
  .MuiDialogContent-root{
   padding: 24px 48px;
  }
}
// working hour dialogs box

.working-hour-dialogbox{
  .MuiButtonBase-root, .cancle_btn-team{
    box-shadow: none !important;
    padding: 6px 16px;
    border-radius: 8px;
  }
  
  .MuiButtonBase-root{
    margin-left: 0;
  }
  .MuiPaper-root.MuiDialog-paper{
    max-width: 501px;
  }
  .MuiDialogTitle-root {
    font-size: 1rem;
    padding: 12px 16px;
  }
  .MuiDialogContent-root {
    padding: 12px 16px 0;
  }
  .working-hour-inner{
    background: #F4F6F8;
    border-radius: 0.625rem 0.625rem 0.425rem;
    padding: 0.7375rem 0rem 0.7375rem 1.2rem;
    margin-top: 0.85rem;
    margin-right: 5px;
    .MuiTypography-root{
      color: #212B36;
  }
  
  }
  .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary{
    padding: 9px 9px 3px;
      }
  .weekend{
    .MuiFormControlLabel-label{
      font-size: 1rem;
      line-height: 1.5rem;
    }
   
  }
   .manually-text{
    font-weight: 700;
    color: #454F5B;
  }
 .ambtn, .pmbtn{
  padding: 0.1rem 1.1rem;
  border: none;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 700;
  border-radius: 0.69rem;
  cursor: pointer;

 }
 .activetime{
  background-color: #00AB55;
  color: #ffffff;
  z-index: 99;
    margin-left: -12px;
 }
 .disabletime{
  color: #454F5B;
  background-color: #DCE0E4;
  margin-left: -12px;
 }
 .time-inner-box{
  padding: 0.875rem 1rem;
  border: 1px solid rgba(145, 158, 171, 0.32);
     border-radius: 8px;
     display: flex;
     align-items: center;
  .MuiInputBase-input.MuiOutlinedInput-input{
    padding: 0;
    border: none;
  }
  .MuiOutlinedInput-notchedOutline{
    border-color: transparent !important;
  }
  .MuiInputBase-root.MuiOutlinedInput-root:hover{
    border-color: transparent !important;
  } 
  ::-webkit-input-placeholder {
    /* Edge */
    // font-style: italic;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.25rem;
    color: #919EAB;
    opacity: 0.6;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    // font-style: italic;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.25rem;
    color: #919EAB;
    opacity: 0.6;
  }

  ::placeholder {
    // font-style: italic;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.25rem;
    color: #919EAB;
    opacity: 0.6;
  }
 }
  

}
.working-time-dialogbox{
  button{
    padding: 0.5rem 2rem;
  }
    
}

//custome hour 
.custom-hour-dialogbox{
  .MuiPaper-root.MuiDialog-paper{
    min-width: 80%;
    max-width: 85%;
  }

  .sethour-new-box{
    background: #F4F6F8;
    border-radius: 1rem;
    padding: 0.938rem 0.81rem 2.125rem 0.81rem;
    display: inline-block;
    width: 18%;
    margin: 1% 0.8% 3%;
    vertical-align: top;
    .MuiTypography-root.MuiTypography-body1{
      color: #212B36;
          }
  }
  .ambtn, .pmbtn{
    padding: 0rem 0.85rem;
    border: none;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 700;
    border-radius: 0.69rem;
    cursor: pointer;
  
   }
   .activetime{
    background-color: #00AB55;
    color: #ffffff;
    z-index: 99;
      margin-left: -12px;
   }
   .disabletime{
    color: #454F5B;
    background-color: #DCE0E4;
    margin-left: -12px;
   }
   .time-inner-box{
    padding: 0.75rem 0.8rem;
    border: 1px solid rgba(145, 158, 171, 0.32);
       border-radius: 8px;
       display: flex;
       align-items: center;
       background-color: #ffffff;
    .MuiInputBase-input.MuiOutlinedInput-input{
      padding: 0;
      border: none;
    }
    .MuiInputBase-root.MuiOutlinedInput-root{
      color: #212B36;
    }
    .MuiOutlinedInput-notchedOutline{
      border-color: transparent !important;
    }
    .MuiInputBase-root.MuiOutlinedInput-root:hover{
      border-color: transparent !important;
    } 
 
    ::-webkit-input-placeholder {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 0.875rem;
      color: #919EAB;
      opacity: 0.6;
    }
  
    :-ms-input-placeholder {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 0.875rem;
      color: #919EAB;
      opacity: 0.6;
    }
  
    ::placeholder {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 0.875rem;
      color: #919EAB;
      opacity: 0.6;
    }
   }
}
.mondaytofridaytext{
  .MuiTypography-root {
    font-size: 0.975rem !important;
  }
}

@media only screen and (max-width:1280px) {
  .MuiPaper-root.MuiDialog-paper{
    min-width: 80%;
    // max-width: 80%;
  }
  .delete-alert{
    .heading_18{
      font-size: 18px;
      line-height: 28px;
    }
    .MuiDialogContent-root{
      padding: 24px;
     }
  }
  .checkbox-light-heading{
      font-size: 16px !important;
      line-height: 24px !important;
  }
  .confirm-btn, .cancle_btn{
    padding: 10px 30px !important;
  }
  .cancle_btn{
    margin-left: 25px !important;
  }
  .warning-dialogsbox{
    padding: 48px 23px 39px;
  }
  .welcomeTripshift {
    .welcomepara {
      padding: 0;
    }
  }

  .addmembers {
    .member-textfield{
      .MuiInputBase-root.MuiOutlinedInput-root{
      min-height: 101px;
    }
    .member-textfield{
      .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline{
        max-height: 78px !important;
        overflow-y: scroll !important;
      }
    }
  }
    .main-para {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 40px;
    }
  }
}
@media only screen and (max-width:600px) {
  .addmembers .member-textfield .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline {
    max-height: 24vw !important;
}
.toast-dialogs .MuiPaper-root.MuiDialog-paper{
  min-width: 90%;
  max-width: 90%;
}
.warning-dialogsbox{
  padding: 48px 23px 20px;
}
.mainly-working{
padding-left: 0 !important;
}
.note-dialogs{
  .MuiPaper-root.MuiDialog-paper{
    min-width: 90%;
    max-width: 90%;
  }
}
.custom-hour-dialogbox{
  .sethour-new-box{
    width: 100%;
    margin: 1% 0% 5% 3%;
  }
}
}
@media (min-width:601px) and (max-width:900px){
  .MuiPaper-root.MuiDialog-paper{
    min-width: 450px;
    max-width: 450px;
  }
  .custom-hour-dialogbox{
    .sethour-new-box{
      width: 45%;
      margin: 1% 2% 5% 2%;
    }
  }
  }


@media (min-width:900px ) and (max-width:1280px){
  .MuiPaper-root.MuiDialog-paper{
    min-width: 350px;
  }
  .custom-hour-dialogbox{
    .sethour-new-box{
    
      width: 30%;
      margin: 1% 0% 5% 3%;
    }
  }
  .addmembers .main-para {
    font-size: 16px;
    line-height: 20px;
  }
}
//add member alert 

.MemberAlert {
  background-color: #fff !important;
  color: #000 !important;
  opacity: 99;
  box-shadow: 0px 8px 16px rgba(145, 158, 171, 0.16) !important;
  border-radius: 8px !important;
  align-items: center !important;
  padding: 12px;
  .email-value {
    font-weight: 600;
    word-break: break-word;
    .MuiTypography-root {
      font-weight: 600;
    }
  }

  .checkicon-bg{
    background: rgba(84, 214, 44, 0.16);
    border-radius: 12px;
    padding: 8px 8px 0px;
    align-items: center;
    text-align: center;
  }

  svg {
    color: #54D62C !important;
  }
.cross_section{
  text-align: center;
}
  .alert_cross {
      color: #212B36 !important;
    opacity: 0.7;
    cursor: pointer;

  }

}


@media (min-width:1920px) and (max-width:6000px){
  .addmembers .member-textfield .MuiInputBase-root.MuiOutlinedInput-root{
      padding-top: 1.5vw;
  }
  .toast-dialogs .MuiPaper-root.MuiDialog-paper {
    min-width: 370px;
    max-width: 11vw;
}
.MuiPaper-root.MuiDialog-paper{
  max-width: 24vw;
}
}
 
